var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('b-spinner',{staticClass:"position-absolute",staticStyle:{"top":"50%","left":"48%"},attrs:{"variant":"primary","label":"Spinning"}}):_c('b-card',[_c('validation-observer',{ref:"companyUpdate"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Entity Type","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Entity Type ","vid":"type_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.types,"reduce":function (type) { return type; },"label":"name","searchable":false},model:{value:(_vm.selectedCompanyType),callback:function ($$v) {_vm.selectedCompanyType=$$v},expression:"selectedCompanyType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.showParentCompany)?_c('b-form-group',{attrs:{"label":"Select parent company","label-for":"parent-company","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Parent company","vid":"parent_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.parentCompanyList,"reduce":function (parent_id) { return parent_id.id; },"placeholder":"Type to search...","label":"name"},on:{"search":function($event){return _vm.onSearchParentCompany($event, _vm.selectedCompanyType.parent_id)}},model:{value:(_vm.form.parent_id),callback:function ($$v) {_vm.$set(_vm.form, "parent_id", $$v)},expression:"form.parent_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1747137657)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Name","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Company Name","vid":"name","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Company Name","autocomplete":"off"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Registered Number","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Registered Number","vid":"registered_number","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Registered Number","autocomplete":"off"},model:{value:(_vm.form.registered_number),callback:function ($$v) {_vm.$set(_vm.form, "registered_number", $$v)},expression:"form.registered_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"VAT ID","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"VAT ID","vid":"vat_id","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"VAT ID","autocomplete":"off"},model:{value:(_vm.form.vat_id),callback:function ($$v) {_vm.$set(_vm.form, "vat_id", $$v)},expression:"form.vat_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Billing Terms ","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Billing Terms ","vid":"billing_term"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.billingTerms,"reduce":function (billing_term) { return billing_term.id; },"label":"name","searchable":false},model:{value:(_vm.form.billing_term),callback:function ($$v) {_vm.$set(_vm.form, "billing_term", $$v)},expression:"form.billing_term"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Currency","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Currency ","vid":"currency"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.currencies,"reduce":function (currency) { return currency.id; },"label":"name","searchable":false},model:{value:(_vm.form.currency),callback:function ($$v) {_vm.$set(_vm.form, "currency", $$v)},expression:"form.currency"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Telephone","label-for":"phone_number","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Telephone","vid":"phone_number","rules":"numeric|min:5|max:15|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input',{ref:"phoneNumber",staticClass:"form-control pl-0",attrs:{"id":"phone_number","name":"phone_number","preferred-countries":['GB'],"auto-default-country":false,"auto-format":false,"default-country":_vm.phoneISO,"dropdown-options":{
                    showDialCodeInSelection: true,
                    showFlags: true,
                    showDialCodeInList: true
                  }},model:{value:(_vm.form.phone_number),callback:function ($$v) {_vm.$set(_vm.form, "phone_number", $$v)},expression:"form.phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"max:255|required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Email","autocomplete":"off"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Web","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Web","vid":"web","rules":"max:255|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Web","autocomplete":"off"},model:{value:(_vm.form.web),callback:function ($$v) {_vm.$set(_vm.form, "web", $$v)},expression:"form.web"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('p',[_vm._v("Billing address:")]),_c('div',{staticClass:"px-50"},[_c('b-form-group',{attrs:{"label":"Address Line 1*","label-for":"billing-address1","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Address Line 1","vid":"billing_address1","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"billing-address1","name":"billing-address1","state":errors.length > 0 ? false:null,"placeholder":"Address Line 1"},model:{value:(_vm.form.billing_address1),callback:function ($$v) {_vm.$set(_vm.form, "billing_address1", $$v)},expression:"form.billing_address1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Address Line 2","label-for":"billing-address2","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Address Line 2","vid":"billing_address2","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"billing-address2","name":"billing-address2","state":errors.length > 0 ? false:null,"placeholder":"Address Line 2"},model:{value:(_vm.form.billing_address2),callback:function ($$v) {_vm.$set(_vm.form, "billing_address2", $$v)},expression:"form.billing_address2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Country*","label-for":"billing-country","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Country","vid":"billing_country_id","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.countries,"reduce":function (country_id) { return country_id.id; },"placeholder":"Select country","label":"name"},model:{value:(_vm.form.billing_country_id),callback:function ($$v) {_vm.$set(_vm.form, "billing_country_id", $$v)},expression:"form.billing_country_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Region/State","label-for":"billing-region","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Region/State","vid":"billing_state","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"billing-region","name":"billing-region","state":errors.length > 0 ? false:null,"placeholder":"Region/State"},model:{value:(_vm.form.billing_state),callback:function ($$v) {_vm.$set(_vm.form, "billing_state", $$v)},expression:"form.billing_state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Town/City*","label-for":"billing-city","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Town/City","vid":"billing_city","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"billing-city","name":"billing-city","state":errors.length > 0 ? false:null,"placeholder":"Town/City"},model:{value:(_vm.form.billing_city),callback:function ($$v) {_vm.$set(_vm.form, "billing_city", $$v)},expression:"form.billing_city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Postcode/Zip Code*","label-for":"billing-postcode","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Postcode/Zip Code","vid":"billing_postcode","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"billing-postcode","name":"billing-postcode","state":errors.length > 0 ? false:null,"placeholder":"Postcode/Zip Code"},model:{value:(_vm.form.billing_postcode),callback:function ($$v) {_vm.$set(_vm.form, "billing_postcode", $$v)},expression:"form.billing_postcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"isSameAddress","name":"checkbox-1","value":1,"unchecked-value":0},model:{value:(_vm.isSameAddress),callback:function ($$v) {_vm.isSameAddress=$$v},expression:"isSameAddress"}},[_vm._v(" Shipping address same as billing ")])],1),_c('p',[_vm._v("Shipping address:")]),_c('div',{staticClass:"px-50"},[_c('b-form-group',{attrs:{"label":"Address Line 1*","label-for":"shipping-address1","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Address Line 1","vid":"shipping_address1","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"shipping-address1","name":"shipping-address1","state":errors.length > 0 ? false:null,"placeholder":"Address Line 1"},model:{value:(_vm.form.shipping_address1),callback:function ($$v) {_vm.$set(_vm.form, "shipping_address1", $$v)},expression:"form.shipping_address1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Address Line 2","label-for":"shipping-address2","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Address Line 2","vid":"shipping_address1","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"shipping-address2","name":"shipping-address2","state":errors.length > 0 ? false:null,"placeholder":"Address Line 2"},model:{value:(_vm.form.shipping_address2),callback:function ($$v) {_vm.$set(_vm.form, "shipping_address2", $$v)},expression:"form.shipping_address2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Country*","label-for":"shipping-country","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Country","vid":"shipping_country_id","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.countries,"reduce":function (country_id) { return country_id.id; },"placeholder":"Select country","label":"name"},model:{value:(_vm.form.shipping_country_id),callback:function ($$v) {_vm.$set(_vm.form, "shipping_country_id", $$v)},expression:"form.shipping_country_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Region/State*","label-for":"shipping-region","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Region/State","vid":"shipping_state","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"shipping-region","name":"shipping-region","state":errors.length > 0 ? false:null,"placeholder":"Region/State"},model:{value:(_vm.form.shipping_state),callback:function ($$v) {_vm.$set(_vm.form, "shipping_state", $$v)},expression:"form.shipping_state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Town/City*","label-for":"shipping-city","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Town/City","vid":"shipping_city","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"shipping-city","name":"shipping-city","state":errors.length > 0 ? false:null,"placeholder":"Town/City"},model:{value:(_vm.form.shipping_city),callback:function ($$v) {_vm.$set(_vm.form, "shipping_city", $$v)},expression:"form.shipping_city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Postcode/Zip Code*","label-for":"shipping-postcode","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Postcode/Zip Code","vid":"shipping_postcode","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"shipping-postcode","name":"shipping-postcode","state":errors.length > 0 ? false:null,"placeholder":"Postcode/Zip Code"},model:{value:(_vm.form.shipping_postcode),callback:function ($$v) {_vm.$set(_vm.form, "shipping_postcode", $$v)},expression:"form.shipping_postcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"isActive","name":"checkbox-1","value":1,"unchecked-value":0},model:{value:(_vm.form.is_active),callback:function ($$v) {_vm.$set(_vm.form, "is_active", $$v)},expression:"form.is_active"}},[_vm._v(" Is active ")])],1)],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","disabled":_vm.isSavingActionInProgress,"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }